<template>
	<v-container fluid tag="section">
		<v-row>
			<v-col cols="12" xl="6">
				<ti-card icon="mdi-link" title="Quick links">
					<template #title>Quick links</template>
					<template>
						<v-list>

							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>App</v-list-item-title>
									<v-list-item-subtitle><a href="https://app.targetinternet.com" target="_blank">https://app.targetinternet.com</a></v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>New admin portal</v-list-item-title>
									<v-list-item-subtitle><a href="https://admin2.targetinternet.com" target="_blank">https://admin2.targetinternet.com</a></v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>Legacy member area login</v-list-item-title>
									<v-list-item-subtitle><a href="https://targetinternet.com/login?v=classic" target="_blank">https://targetinternet.com/login?v=classic</a></v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

						</v-list>
					</template>
				</ti-card>
			</v-col>
			<v-col cols="12" xl="6">
				<mr-invitations :invitations="dashboard.declined_invitations"></mr-invitations>
			</v-col>
		</v-row>

	</v-container>
</template>

<script>
import MrInvitations from "./partials/MrInvitations";
import store from "../../store";

export default {
	name: 'DashboardView',
	components: {
		MrInvitations
	},
	data: () => ({
		dashboard: {},
	}),
	beforeMount() {
		this.$api.dashboard.index()
			.then(response => {
				this.dashboard = response.data
			})
			.catch(error => {
				console.log(error);
				this.$toast.add(error.message, 'error')
			})
	}
}
</script>
