<template>
	<ti-card :icon="$icons.topic">
		<template #title>Declined invitations</template>
		<template>
			<v-data-table :headers="headers" :items="invitations" item-key="id" @click:row="linkToCorporate" :item-class="() => 'hover-pointer'"></v-data-table>
		</template>

	</ti-card>
</template>

<script>
export default {
	name: "MrInvitations",
	props: ['invitations'],
	data: () => ({
		headers:[
			{
				text: 'Email',
				align: 'start',
				sortable: true,
				value: 'email',
			},
			{
				text: 'Corporate',
				align: 'start',
				sortable: true,
				value: 'corporate_name',
			},
		]
	}),
	methods:{
		linkToCorporate(item){
			this.$router.push({
				name: 'CorporatesEdit',
				params: {
					id: item.corporate_id
				}
			})
		}
	}
}
</script>

<style scoped>

</style>